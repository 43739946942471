<script>
  import { onMount } from "svelte";
  export let display;
  export let epoch;
  export let selectedFunction;

  epoch = "00:00:00.0"
  selectedFunction="timer";		// or "countdown"


  let dsec_counter = 0;
  let tick = 1/10;	/* sec */

  var timer = "";
  var running = false;

  function inc_counter(){
var t = dsec_counter++;
var hours   = (t / (3600/tick) >> 0); t=t-(3600/tick * hours);
var minutes = (t / (  60/tick) >> 0); t=t-(  60/tick * minutes);
var seconds = (t / (   1/tick) >> 0); t=t-(   1/tick * seconds);
var dsec    = t;
hours = (hours % 24);

display = (hours < 10 ? "0" : "") + hours
        + ":" + (minutes < 10 ? "0" : "") + minutes
        + ":" + (seconds < 10 ? "0" : "") + seconds
        + "." + dsec;
}


function startTimer()
{
  if (timer != "") clearInterval(timer);	// stop any running timer
  timer = setInterval(inc_counter, (1000*tick) /*msec */ );
  running = true;
  console.log("start", timer);
}

function stopTimer()
{
  console.log("stop", timer);
  clearInterval(timer);
  running = false;
}

function resetTimer() {
	display = epoch;	// force screen update
	dsec_counter = 0;
}



function functionTimer(event){
	selectedFunction="timer";
	var elFunc2=document.getElementById("func2");
	/* toggle both buttons' opacity */
        event.target.style.opacity = 1; 
	if (elFunc2) elFunc2.style.opacity = 0.6;
	oldIconOpacity = 1;
}

function functionCountdown(event){
	selectedFunction="countdown";
	var elFunc1=document.getElementById("func1");
	/* toggle both buttons' opacity */
        event.target.style.opacity = 1; 
	if (elFunc1) elFunc1.style.opacity = 0.6;
	oldIconOpacity = 1;
}

var oldIconOpacity; var oldIconColor;
function functionSelectorIconEnter(event){
        oldIconOpacity = event.target.style.opacity;
	oldIconColor = event.target.style.color;
        event.target.style.opacity = 1; 
        event.target.style.color  = '#FCCF00'; /* bright yellow */; 
}

function functionSelectorIconLeave(event){
        event.target.style.opacity = oldIconOpacity; 
        event.target.style.color  = oldIconColor;
}

onMount(async () => {
  var elFunc1=document.getElementById("func1");
  if (elFunc1) elFunc1.style.opacity = 1;		// select timer function
});
</script>

<style>

:root {	/* define variables */
    --bright-color: #FCCF00; /* bright yellow */
    --lite-color: #efe6ad; /* light yellow */
}

 #container {
     text-align:center;
     border:1px solid var(--bright-color);
     border-radius: 0.3em;
     margin:15px auto;
     padding:15px;
}

 .outer-div {
     display: flex;
     align-items: center;
     justify-content: center;
     height: 100%;
    /* needed in order to have a vertically centered middle-div */
}

 #container > h4 {
     color: var(--bright-color);
}

 #timer {
     font-family: 'SkyfontRegular';
     color: black;
     background-color: var(--bright-color); /* bright yellow */
     font-size: 8em;
     padding: 0px;
     display:block;
}
 .icon-play-outline, .icon-pause-outline, .icon-reload,
 .icon-timer, .icon-hour-glass {
     margin-top: 2em;
     font-size: 4em;
     color: var(--lite-color);
     padding-left: 0.5em;
     padding-right: 0.5em;
     cursor: default;
     -webkit-transition: color 0.7s; /* For Safari 3.0 to 6.0 */
     -moz-transition: color 0.7s;
     -o-transition: color 0.7s;
     transition: color 0.7s; /* For modern browsers */
}

 /* .icon-timer:hover, .icon-hour-glass:hover {
*/
 .icon-play-outline:hover, .icon-pause-outline:hover, .icon-reload:hover {
     color: var(--bright-color);
}

 .action {
     position: relative;
     display: inline-block;
}
 .tooltiptext {
     top: -10%;
     right: -10%;
}
 .action:hover .tooltiptext {
     visibility: visible;
     opacity: 1;
}

/* Tooltip text */
 .tooltiptext {
     visibility: hidden;
     background-color: black;
     color: var(--lite-color);
     text-align: center;
     padding: 0px;
    /* Position the tooltip text - see examples below! */
     position: absolute;
     z-index: 1;
    /* Fade in tooltip */
     opacity: 0;
     transition: opacity 1s;
}

 #buttons {
     margin-top: 4em;
}

 @font-face {
     font-family: 'SkyfontRegular';
    /*subset: latin (see https://transfonter.org/) */
     src: url('subset-SkyfontRegular.woff2') format('woff2'), url('subset-SkyfontRegular.woff') format('woff');
     font-weight: normal;
     font-style: normal;
}

 @font-face {
     font-family: 'icomoon';
     src: url('fonts/icomoon.woff2') format('woff2'),
          url('fonts/icomoon.woff') format('woff');
     /*
     src: url(data:font/woff;charset=utf-8;base64,d...A=) format('woff');
     */
     font-weight: normal;
     font-style: normal;
     font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-hour-glass:before {
  content: "\e900";
}
.icon-pause-outline:before {
  content: "\e901";
}
.icon-play-outline:before {
  content: "\e902";
}
.icon-reload:before {
  content: "\e903";
}
.icon-timer:before {
  content: "\e904";
}


#functionSelector {
  text-align: left;
}
.functionSelectorIcon {
  font-size: 1.25em;
  opacity: 0.6;
}


</style>

<main>

<div class="middle-div">

<div id="container">


<div id="functionSelector">
 <span class="action">
<span id="func1"
      on:click={functionTimer}
      on:mouseenter={functionSelectorIconEnter}
      on:mouseleave={functionSelectorIconLeave}
      class="icon-timer      functionSelectorIcon"></span>
<!--
   <span class="tooltiptext">Χρονόμετρο</span>
-->
 </span>
 <span class="action">
<span id="func2"
      on:click={functionCountdown}
      on:mouseenter={functionSelectorIconEnter}
      on:mouseleave={functionSelectorIconLeave}
      class="icon-hour-glass functionSelectorIcon"></span>
<!--
   <span class="tooltiptext">Αντίστροφη μέτρηση</span>
-->
 </span>
</div>

<h4>Χρονόμετρο</h4>

<div>
{#if display}
<span id="timer">{display}</span>
{:else}
<span id="timer">{epoch}</span>
{/if}
</div>



<div id="buttons">
<p>
 <span class="action">
   <span on:click={startTimer} class="icon-play-outline"></span>
   <span class="tooltiptext">Έναρξη</span>
 </span>
 <span class="action">
   <span on:click={stopTimer} class="icon-pause-outline"></span>
   <span class="tooltiptext">Διακοπή</span>
 </span>
 <span class="action">
   <span on:click={resetTimer} class="icon-reload"></span>
   <span class="tooltiptext">Επαναφορά</span>
 </span>

</p>
</div>


</div><!-- container -->
</div><!-- middle-div -->
</main>
